<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="processTable2"
          title="단위공정 목록"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          :filtering="false"
          @rowClick="rowClick"
          gridHeight="540px"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <q-form ref="editForm">
          <c-card title="단위공정 상세" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="editable"
                  label="단위공정명"
                  name="processName"
                  v-model="data.processName">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="단위공정코드"
                  name="processCd"
                  v-model="data.processCd">
                </c-text>
              </div>
              <div class="col-3">
                <c-radio
                  :editable="editable"
                  :comboItems="useFlagItems"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="data.useFlag">
                </c-radio>
              </div>
              <div class="col-12">
                <c-dept-multi label="관리부서" :disabled="true" name="managementDepts" v-model="data.managementDepts" />
              </div>
              <div class="col-12">
                <c-textarea
                  :editable="editable"
                  label="단위공정 설명"
                  name="processDesc"
                  :rows="2"
                  v-model="data.processDesc">
                </c-textarea>
              </div>
              <div class="col-3">
                <c-select
                  :editable="editable"
                  codeGroupCd="WORK_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="workCycleCd"
                  label="작업발생 주기"
                  v-model="data.workCycleCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  type="number"
                  label="작업시간(1회)"
                  name="workingTime"
                  v-model="data.workingTime">
                </c-text>
              </div>
              <div class="col-12">
                <c-upload
                  :attachInfo="attachInfo1"
                  :editable="editable"
                  label="단위공정 사진">
                </c-upload>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'process-unit-pop',
  props: {
    processCd: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      attachable: false,
      attachInfo1: {
        isSubmit: '',
        taskClassCd: 'PROCESS_UNIT_PHOTO',
        taskKey: '',
      },
      comboItems: [
        { code: 'Y', codeName: '상위공정정보 동일' },
      ],
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      grid: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '단위공정명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        orderNo: '',
        useFlag: 'Y',
        psmFlag: 'Y',
        workCycleCd: null,
        workingTime: null,
        col1: []
      },
      listUrl: '',
      detailUrl: '',
      editable: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = false;
      // api scope
      this.listUrl = selectConfig.mdm.process.list.url;
      this.detailUrl = selectConfig.mdm.process.get.url;
      this.getList({ processCd: this.processCd });
    },
    getList(row) {
      this.rowNotSelected = true;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        processLevelCd: '20',
        upProcessCd: row.processCd,
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    rowClick(row) {
      // 상세조회
      this.$http.url = this.$format(this.detailUrl, row.processCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.$set(this.attachInfo1, 'taskKey', row.processCd);
      },
      () => {
      });
    },
  }
};
</script>
